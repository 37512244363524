#wrapper {
    margin-right: 20px;
  }
  
  .profile-main-loader {
    position: relative; /* Remove the fixed positioning */
  }
  
  .profile-main-loader .loader {
    position: relative;
  
    width: 25px;
    height: 25px;
  }
  .profile-main-loader .loader:before {
    content: "";
    display: block;
  }
  
  .circular-loader {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
  
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
  }
  
  .loader-path {
    stroke-dasharray: 150, 200;
    stroke-dashoffset: -10;
    -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }
  
  .loading-container {
    display: inline-block;
  }
  
  .info {
    display: flex;
    flex-direction: row;
  
    align-items: center;
  }
  
  /* Rest of your CSS... */
  
  @-webkit-keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
    }
  }
  @-webkit-keyframes color {
    0% {
      stroke: #6c7284;
    }
    40% {
      stroke: #6c7284;
    }
    66% {
      stroke: #6c7284;
    }
    80%,
    90% {
      stroke: #6c7284;
    }
  }
  @keyframes color {
    0% {
      stroke: #6c7284;
    }
    40% {
      stroke: #6c7284;
    }
    66% {
      stroke: #6c7284;
    }
    80%,
    90% {
      stroke: #6c7284;
    }
  }
  