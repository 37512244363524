.Tab.block {
  display: grid;
  grid-auto-flow: column;
  font-size: var(--font-base);
  border-radius: 3px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.7);
  background: var(--pallete-background-3);
  box-shadow: inset 0px 0px 30px 5px rgba(255, 255, 255, 0.01);
}

.Tab.block .Tab-option {
  text-align: center;
  padding: 0.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
}

.Position-tab-number {
  white-space: nowrap;
  font-size: var(--font-xs);
  width: 18px;
  height: 18px;
  font-weight: lighter;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  margin-left: 5px;
  justify-content: center;
  color: #6c7284;
}

.Tab.block .Tab-option:hover {
  color: var(--text-color);
  background: var(--tab-background);
}

.Tab.block .Tab-option.active {
  opacity: 1;
  pointer-events: none;
  background: var(--pallete-main);
  color: rgba(255, 255, 255, 1);
}

.Tab.block .Tab-option.active span {
  opacity: 1;
  pointer-events: none;
  background: var(--pallete-main);
  color: #6c7284 !important;
}

.Tab.block .Tab-option.active .Tab-option-icon {
  opacity: 1;
}

.Tab.inline {
  overflow-x: scroll;
  white-space: nowrap;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.Tab.inline::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.Tab.inline .Tab-option {
  cursor: pointer;
  display: inline-block;
  margin-right: 1.5rem;
  font-size: var(--font-base);
}

.Tab.inline .Tab-option:hover {
  opacity: 0.8;
}

.Tab.inline .Tab-option.active {
  opacity: 1;
  pointer-events: none;
}

.Tab.exchange {
  display: grid;
  grid-auto-flow: column;
  font-size: var(--font-base);
  overflow: hidden;
  color: var(--pallete-inactive);
}

.Tab.exchange .Tab-option {
  text-align: center;
  padding: 21px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.Tab.exchange .Tab-option.Swap {
  display: none;
}

.Tab.exchange .Tab-option.active {
  font-weight: bold;
}

.Tab-option-icon {
  filter: invert(46%) sepia(6%) saturate(1132%) hue-rotate(188deg) brightness(93%) contrast(86%);
}

.Tab.exchange .Tab-option.Long.active {
  opacity: 1;
  pointer-events: none;
  border-bottom: 2px solid var(--pallete-green);
}

.Tab.exchange .Tab-option.Long.active .Tab-option-swapbox {
  color: var(--pallete-green) ;
}

.Tab-option-icon.Long.active {
  filter: invert(67%) sepia(96%) saturate(2941%) hue-rotate(113deg) brightness(88%) contrast(103%);
}


.Tab.exchange .Tab-option.Long:hover {
  border-bottom: 2px solid var(--pallete-green);
}

.Tab.exchange .Tab-option.Short.active {
  opacity: 1;
  border-bottom: 2px solid var(--pallete-red);
}

.Tab.exchange .Tab-option.Short.active .Tab-option-swapbox {
  color: var(--pallete-red);
}


.Tab.exchange .Tab-option.Short:hover {
  border-bottom: 2px solid var(--pallete-red);
}


.Tab-option-icon.Short.active {
  filter: invert(43%) sepia(40%) saturate(1804%) hue-rotate(324deg) brightness(91%) contrast(118%);
}


.Tab.order {
  display: grid;
  grid-auto-flow: column;
  font-size: var(--font-base);
  line-height: 1.5;
  border-radius: 1rem;
  overflow: hidden;
  color: var(--pallete-inactive);
  background: var(--pallete-background-3);
  box-shadow: inset 0px 0px 30px 5px rgba(255, 255, 255, 0.01);
  margin-bottom: 1rem;
}

.Tab.order .Tab-option {
  text-align: center;
  padding: 0.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;

  &.active {
    opacity: 1;
    pointer-events: none;
    background: var(--pallete-background-6);
    color: var(--text-color);
    border-radius: 10px;

    .Tab-option-icon {
      opacity: 1;
    }
  }
}

.Tab.table {
  display: flex;
  font-size: var(--font-base);
  overflow: hidden;
  color: rgba(255, 255, 255, 0.7);

  .Tab-option {
    text-align: center;
    padding: 0.8rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    cursor: pointer;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    display: flex;
    align-items: center;
    margin-top: 6px;

    .Position-tab-number {
      font-size: var(--font-xs);
      font-weight: normal;
    }

    &:hover {
      color: var(--text-color);
    }

    &.active {
      font-weight: bold;
      opacity: 1;
      pointer-events: none;
      background: var(--pallete-background-5-v2-only);
      color: var(--text-color);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-top: 1px solid var(--pallete-border);
      border-left: 1px solid var(--pallete-border);
      border-right: 1px solid var(--pallete-border);
      border-bottom: 1px solid var(--pallete-background-5-v2-only);
      z-index: 10;
    }
  }
}

.Tab-option-swapbox {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

@media (max-width: 1100px) {
  .Tab-option-swapbox img {
    display: none;
  }
}

.Tab-option-label-red {
  color: red !important;
}
