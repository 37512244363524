.wallet-provider {
  color: var(--pallete-inactive);
  margin-left: 5px;
}

.disconnect-btn {
  width: 87px;
  height: 25px;
  border-radius: 6px;
  background-color: rgba(106, 63, 233, 0.2);
  color: #6a3fe9;
  border: none;
  font-size: var(--font-sm);
}

.disconnect-btn:hover {
  color: white !important;
  background-color: var(--pallete-main);
}

.address-link {
  color: var(--pallete-inactive) !important;
  white-space: nowrap;
  text-decoration: none;
}
