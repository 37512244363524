.Exchange-trigger-order-info {
  display: flex;
}

.Exchange-trigger-order-info,
.Exchange-trigger-order-info a {
  color: var(--pallete-inactive);
  border: 1px solid var(--pallete-border);
  background: transparent !important;
}

.Exchange-list-tab-container {
  display: grid;
  grid-template-columns: auto auto;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  margin-top: 0.4rem;
  border: 1px solid var(--pallete-border);
  align-items: center;
}

.Exchange-should-show-position-lines {
  font-size: var(--font-base);
}
.Exchange-should-show-position-lines .active {
  opacity: 1;
}

.Exchange-empty-positions-list-note {
  position: relative;
}
.Exchange-empty-positions {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.Exchange-inactive-wrapper {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.Exchange-inactive-wrapper > p {
  margin-top: 1em;
  margin-bottom: 1em;
}

.Exchange-inactive {
  width: 141px;
  height: 37px;
  background-color: #6a3fe9;
  border: none;
  border-radius: var(--border-radius-sm);
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
}

.Exchange-inactive:hover {
  background-color: #8861ff !important;
}

.Exchange-connect-wallet {
  margin-left: 0.4rem;
  font-size: var(--font-sm);
  color: var(--pallete-white);
}

.Exchange-list-image-and-title {
  display: flex;
  align-items: center;
}

.Exchange-list-leverage {
  display: flex;
  margin-left: 0.5rem;
  flex-direction: column;
}

.position-loading-icon {
  display: inline-block;
  font-size: var(--font-arbitrary-1-25rem);
  margin-left: 0.8rem;
  color: #3749e6;
  vertical-align: middle;
  margin-top: -0.31rem;
}

.Exchange-settings-row {
  margin-bottom: 0.8rem;
}

.Exchange-settings .App-cta {
  margin-top: 1.5rem;
}

.Exchange-content {
  display: flex;
  gap: 0.4rem;
  padding-top: 0rem;
  flex: 1 1 0%;

  @media (max-width: 1100px) {
    flex-direction: column;

    .Exchange-chart-column {
      order: 1 !important;
    }
    .Exchange-form-column {
      order: 2 !important;
    }
  }

  .Exchange-chart-column {
    flex: 1 1 0%;
    min-height: 100%;
    display: flex;
    flex-flow: column;
    min-width: 0;

    & > .Exchange-lists {
      flex-grow: 1;
    }
  }

  .Exchange-form-column {
    flex-shrink: 0;
  }

  &.Exchange-content--layout-left {
    .Exchange-chart-column {
      order: 2;
    }
    .Exchange-form-column {
      order: 1;
    }
  }

  &.Exchange-content--layout-right {
    .Exchange-chart-column {
      order: 1;
    }
    .Exchange-form-column {
      order: 2;
    }
  }
}

.Exchange-position-list-orders {
  white-space: nowrap;
}

.Exchange-leverage-slider-settings {
  font-size: var(--font-base);
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
}

.Exchange-leverage-slider-settings .Indicator {
  font-size: var(--font-sm);
  line-height: 2.4rem;
  border-radius: 1rem;
  border: 1px solid var(--pallete-border);
  background-color: var(--pallete-background-3);
  padding: 0.5rem 1.4rem;
  box-sizing: border-box;
}

.Indicator {
  display: flex;
  width: 70px;
  justify-content: center;
}
.Indicator-input {
  width: auto;
  font-size: var(--font-sm);
  padding: 0;
  color: var(--text-color);
}

.Exchange-wallet-tokens {
  position: relative;
  min-height: 15.5rem;
  display: none;
}

.Exchange-wallet-tokens-content {
  position: absolute;
  top: 1.5rem;
  bottom: 0;
  left: 0;
  right: 0;
}

.Exchange-bottom-header-item {
  display: inline-block;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  padding-left: 0.155rem;
  opacity: 0.7;
  cursor: pointer;
}

.Exchange-bottom-header-item.active {
  opacity: 1;
  pointer-events: none;
}

.Exchange-swap-connect-wallet {
  text-align: center;
  cursor: pointer;
  border: 1px solid rgb(50, 50, 50);
  border-radius: 3px;
}

.Exchange-swap-connect-wallet:hover {
  background: #272e33;
  border-radius: 0.6rem;
}

.Exchange-swap-settings.Exchange-swap-txns-status {
  text-align: right;
  margin-right: 1.5rem;
}

.Exchange-swap-account .Exchange-swap-address:hover,
.Exchange-swap-account .Exchange-swap-txns-status:hover {
  opacity: 1;
}

.Exchange-leverage-slider {
  margin-top: 1.5rem;
  margin-bottom: 3.4rem;
  padding: 0 0.8rem;
}

.Exchange-swap-leverage-options {
  text-align: right;
}

.Exchange-swap-leverage-option {
  display: inline-block;
  margin-left: 0.8rem;
  padding: 0 0.8rem;
  border-radius: 3px;
  background: #5b2ab0;
  color: white;
  opacity: 0.7;
  cursor: pointer;
}

.Exchange-swap-leverage-option.active {
  background: #842ec9;
  opacity: 1;
  pointer-events: none;
}

.Exchange-swap-leverage-option:hover {
  opacity: 1;
}

.Exchange-swap-leverage-unlock-icon {
  font-size: var(--font-arbitrary-0-8rem);
  vertical-align: middle;
  margin-top: -0.31rem;
}

.Exchange-swap-placeholder {
  height: 11.175rem;
  position: relative;
}

.Exchange-swap-Event {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.Exchnage-swap-Event-title,
.Exchnage-swap-Event-subtext {
  margin: 0;
}

.Event-link {
  text-decoration: none !important;
}

.Exchnage-swap-Event-title {
  font-size: var(--font-md);
  line-height: 1.5em;
  white-space: nowrap;
  font-family: "Mazzard H ExtraBold", sans-serif;
}

.Exchnage-swap-Event-subtext {
  line-height: 1.5em;
  text-decoration: underline;
  color: var(--pallete-main);
  font-size: var(--font-base);
}

.Exchange-swap-Event-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 164px;
}

.Exchange-swap-Event-image {
  width: 80px;
  height: 80px;
}

.Exchange-wave {
  z-index: 1;
  position: absolute;
  left: 1.5rem;
  right: 1.5rem;
  top: 1.5rem;
  bottom: 1.5rem;
  mask-image: url(../../img/wave-01.png);
  mask-size: 90% 90%;
  mask-repeat: no-repeat;
  mask-position: center;
  filter: blur(5rem);
  background: linear-gradient(
    40deg,
    rgba(96, 4, 189, 1) 0%,
    rgba(65, 105, 224, 1) 22%,
    rgba(99, 8, 191, 1) 23%,
    rgba(51, 9, 115, 1) 37%,
    rgba(255, 36, 145, 1) 38%,
    rgba(201, 16, 161, 1) 50%,
    rgba(171, 10, 163, 1) 59%,
    rgba(117, 4, 128, 1) 67%,
    rgba(96, 29, 196, 1) 68%,
    rgba(92, 55, 204, 1) 84%,
    rgba(84, 88, 214, 1) 100%
  );
  pointer-events: none;
}

.PositionEditor-info-box {
  margin-bottom: 1.05rem;
}

.PositionEditor-keep-leverage-settings .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: var(--font-base);
  margin-bottom: 0.465rem;
}

.PositionEditor-accept-profit-warning .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: var(--font-base);
  margin-bottom: 0.465rem;
}

.PositionEditor-allow-higher-slippage .Checkbox {
  height: 2.4rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: var(--font-base);
  margin-bottom: 0.465rem;
}

.ExchangeChart-range-option {
  display: inline-block;
  margin-right: 1.5rem;
  font-size: var(--font-base);
  cursor: pointer;
  opacity: 0.6;
}

.ExchangeChart-range-option:hover {
  opacity: 0.8;
}

.ExchangeChart-range-option.active {
  opacity: 1;
}

.ExchangeChart {
  position: relative;
  height: 49.6rem;
}

.ExchangeChart-second-block {
  width: 11.5rem;
}

.ExchangeChart-second-block,
.ExchangeChart-additional-info {
  font-size: var(--font-sm);
  font-weight: 500;
}

.skeleton {
  height: 15px;
  width: 100%;
  max-width: 67px;
  border-radius: 5px;
  background-color: transparent;
  overflow: hidden;
  position: relative;
}

.skeleton::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ExchangeChart-info-label {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.3;
  color: var(--pallete-inactive);
}

.ExchangeChart-additional-info .Tooltip-handle {
  text-decoration: none;
}

.ExchangeChart-info-percentage {
  display: flex;
  align-items: center;
  width: fit-content;
}

.ExchangeChart-dollar-sign {
  display: inline-block;
  margin-right: 0.465rem;
  opacity: 0.7;
}

.ExchangeChart-top {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: auto;
  z-index: 2;
}

.ExchangeChart.tv .ExchangeChart-top {
  position: relative;

  padding: 0 2rem;

  border: none;
  z-index: 2;
  border-right: 1px solid var(--pallete-border);
  border-top: 1px solid var(--pallete-border);
}

.ExchangeChart.tv .ExchangeChart-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 6.1rem;
  z-index: 1;
}
.ExchangeChart-bottom-controls {
  display: inline-block;
}
.ExchangeChart-bottom-controls .Tab .Tab-option {
  width: 4.65rem;
  padding: 0.8rem 0;
}
.ExchangeChart.tv .ExchangeChart-bottom-header {
  position: absolute;
  display: flex;
  top: 1.5rem;
  left: 1.5rem;
  right: 1.5rem;
  z-index: 5;
}
.ExchangeChart.tv .ExchangeChart-bottom-stats {
  font-size: var(--font-base);
  padding: 0.8rem 0;
  margin-left: 3.1rem;
  white-space: nowrap;
  z-index: 2;
}
.ExchangeChart-bottom-stats-label {
  color: rgba(255, 255, 255, 0.7);
  font-weight: bold;
}
.ExchangeChart-bottom-stats-value {
  display: inline-block;
  margin-left: 0.31rem;
  margin-right: 0.8rem;
}
.length-5 .ExchangeChart-bottom-stats-value {
  width: 6.2rem;
}
.length-4 .ExchangeChart-bottom-stats-value {
  width: 5.25rem;
}
.length-3 .ExchangeChart-bottom-stats-value {
  width: 4.35rem;
}
.length-2 .ExchangeChart-bottom-stats-value {
  width: 3.41rem;
}
.length-1 .ExchangeChart-bottom-stats-value {
  width: 2.48rem;
}

.ExchangeChart.tv .ExchangeChart-bottom-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.Exchange-price-tooltip {
  font-size: var(--font-base);
  background: linear-gradient(90deg, rgba(57, 28, 147, 0.9) 0%, rgba(66, 24, 140, 0.9) 100%);
  padding: 0.9rem;
  border: 1px solid rgba(30, 9, 94, 0.5);
  line-height: 2rem;
}

.Exchange-price-time {
  font-size: var(--font-arbitrary-1-25rem);
  font-weight: normal;
}

.Error-modal .Modal-content {
  width: 31rem;
}

.PositionEditor .Modal-content {
  width: 38rem;
  position: absolute;
  max-height: 80vh;
  overflow-y: auto;
  border: none;
  top: auto;
  bottom: auto;
}

.PositionEditor .Modal-content .Modal-body {
  overscroll-behavior: smooth;
  max-height: calc(80vh - 8.525rem);
  overflow-y: auto;
}

.PositionEditor .Tab {
  margin-bottom: 1.05rem;
}

.PositionEditor-token-symbol {
  font-size: var(--font-sm);
  line-height: 2.4rem;
  text-align: right;
}

.ExchangeChart-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.Exchange-list {
  width: 100%;
}

.Position-list-order {
  white-space: nowrap;
  margin-top: 0.8rem;
}

.active-order-tooltip {
  background: var(--dark-blue-hover);
  padding: 0.75rem;
  border-radius: var(--border-radius-sm);
  white-space: normal;
}
.active-order-tooltip > .active-oredr-error {
  margin-top: 0.5rem;
}

.order-error span {
  text-decoration-color: var(--error-red);
  color: var(--error-red);
}

.order-error .Tooltip-popup {
  white-space: pre-line;
}

.Exchange-list .App-card {
  margin-bottom: 1.5rem;
}

.Exchange-list-item-error {
  color: #fa3c58;
  margin-top: 0.465rem;
  font-size: var(--font-base);
}

button.Exchange-list-action {
  box-sizing: border-box;
  width: 7.4rem;
  height: 2.6rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: visible;
  padding: 0px 0px 0px 0px;
  align-content: center;
  border-radius: var(--border-radius-sm);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.Exchange-list tr td:last-child {
  text-align: right;
  padding: 0;
}

.ClosedPositionsList tr td:last-child {
  text-align: left;
}

.Exchange-position-close {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  padding-left: 0;
  padding-right: 0;
}

@media (max-width: 1100px) {
  .Exchange-position-close {
    width: 4rem;
    color: var(--pallete-inactive);
  }
}

.Exchange-position-end {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  height: 6.5rem;
}

.Exchange-position-dropdown {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  padding-left: 0;
  padding-right: 0;
}

.Exchange-list tr:nth-child(2) td:nth-child(10) {
  text-align: right;
  padding: 0;
  margin: 0;
  align-items: flex-end;
}

table.Exchange-list {
  border-collapse: collapse;
}

table.Exchange-list th,
table.Exchange-list td {
  font-weight: normal;
  text-align: left;
  padding-left: 0.98rem;
  padding-right: 0;
  max-width: 16rem;
}
table.Exchange-list.Orders th {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

table.Exchange-list tr {
  border-bottom: 1px solid var(--pallete-border);
  font-size: var(--font-sm);
}

table.Exchange-list tr:last-child {
  border-bottom: none;
}

table.Exchange td:last-child {
  text-align: right;
  height: 30rem;
}

table.Exchange-list tr.Exchange-list-header {
  background: var(--pallete-background-3);
  color: #6c7284;
  font-size: var(--font-sm);
  height: 4rem;
  border-radius: 1rem;
  border-bottom: none;
  white-space: nowrap;
}

.Exchange-list-no-position {
  width: 100%;
  height: 23.85rem;
  display: flex;
  align-items: center;
  font-size: var(--font-sm);
  color: var(--pallete-inactive, #6c7284);
}

table.Exchange-list tr.Exchange-list-body {
  height: 65px;
  box-sizing: border-box;
  border-color: var(--Border_Color, rgba(255, 255, 255, 0.05));
  border-style: solid;
  border-top-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.Exchange-list-info-liquidation-price {
  color: #d9a94a;
}

.ExchangeChart-second-block:first-child,
.ExchangeChart-additional-info:first-child {
  width: 11.75rem;
}

.Exchange-list-info-PnL {
  width: 16.5rem;
}
.Exchange-list-info-mark-price {
  width: 8.5rem;
}

.Exchange-list-info-leverage {
  width: 9rem;
}

table.Exchange-list tr.Exchange-list-header th {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

table.Exchange-list tr.Exchange-list-header th.Start {
  border-top-left-radius: var(--border-radius-sm);
  border-bottom-left-radius: var(--border-radius-sm);
}

table.Exchange-list tr.Exchange-list-header th.End {
  border-top-right-radius: var(--border-radius-sm);
  border-bottom-right-radius: var(--border-radius-sm);
  text-align: right;
  padding-right: 1.5rem;
}

.OrdersList,
.TradeHistory,
.PositionsList,
.ClosedPositionsList {
  flex-grow: 1;
  background: var(--pallete-background-5-v2-only);
  padding: 16px;
  border-top: 1px solid var(--pallete-border);
  border-right: 1px solid var(--pallete-border);
  border-bottom: 1px solid var(--pallete-border);
}

table.Exchange-list th {
  opacity: 0.7;
}

.Exchange-leverage-box {
  margin-bottom: 1.05rem;
}

.Exchange-swap-box-info {
  margin-bottom: 1.05rem;
}

.Exchange-info-row {
  display: grid;
  grid-template-columns: auto auto;
  font-size: 1.2rem;
  line-height: 2.4rem;
  margin-bottom: 0.1rem;
}

/* Used in panel under trade form + in close modal panel */
.Exchange-info-row:nth-child(1) {
  margin-top: 1.5rem;
}

.Exchange-info-row.top-line {
  border-top: 1px solid var(--pallete-border);
  margin-top: 1.5rem;
  padding-top: 1.5rem;
}

.Exchange-info-label {
  color: var(--pallete-inactive);
  margin-right: 0.8rem;
}

.Exchange-info-value-warning {
  color: #fa3c58;
}

.Confirmation-box-info,
.Confirmation-box-info a,
.Confirmation-box-warning,
.Confirmation-box-warning a {
  font-size: var(--font-base);
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  max-width: 320px;
  padding-left: 1rem;
  padding-right: 1rem;
  word-break: break-word;
}
.Confirmation-box-warning::-webkit-scrollbar {
  display: none;
}

.Confirmation-box-info,
.Confirmation-box-info a {
  opacity: 0.7;
}

.Confirmation-box-warning,
.Confirmation-box-warning a {
  color: #fa3c58;
}

.Exchange-swap-usd {
  display: inline-block;
}

.Exchange-swap-box {
  width: 41.85rem;
  min-height: 100%;
  position: relative;
  border: 1px solid var(--pallete-border);
  background: var(--pallete-background-5-v2-only);
}

.Exchange-swap-market-box-title {
  font-size: 1.3rem;
  line-height: 2.4rem;
}

.Exchange-swap-box-inner {
  padding: 1.5rem;
  border-top: 1px solid var(--pallete-border);
}

.Exchange-swap-market-box {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1.5rem;
}

.Exchange-swap-market-box .App-card-divider {
  margin: 0 0rem 1.5rem;
}
.Exchange-info-label-button a {
  color: var(--pallete-inactive);
}

.Exchange-swap-box .Radio {
  margin-bottom: 1.05rem;
}

.Exchange-swap-box .Overlay-content-outer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 15.5rem;
}

.Exchange-swap-box .Overlay-content {
  padding: 1.05rem;
}

.Exchange-swap-section {
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
  border: 1px solid var(--pallete-border);
  background-color: var(--pallete-background-3);
  box-sizing: border-box;
}

.Exchange-swap-section:hover {
  border: 1px solid #434f6b;
}

.Exchange-swap-section.inputActive {
  border: 1px solid var(--pallete-border-active);
}

.Exchange-swap-option-tabs.Tab.block .Tab-option {
  padding: 1.05rem;
}

.Exchange-swap-option-tabs.Tab.block .Tab-option-icon {
  margin-top: -0.155rem;
  transform: scale(0.75);
  vertical-align: middle;
  margin-right: 0.8rem;
}

.Exchange-swap-section-top {
  display: grid;
  grid-template-columns: auto auto;
  font-size: var(--font-sm);
  line-height: 1.5;
  margin-bottom: 0.5rem;
}

.Exchange-swap-section-bottom {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 0.5rem;
}

.Exchange-swap-message {
  font-size: var(--font-base);
  opacity: 0.7;
  margin: 0.5rem 0;
}

.Exchange-swap-input-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Exchange-swap-input-container .Exchange-swap-max {
  flex-shrink: 0;
}

.Exchange-swap-max {
  z-index: 1;
  cursor: pointer;
  color: var(--pallete-white);
  background: var(--pallete-main);
  font-size: var(--font-xs);
  height: 1.8rem;
  padding: 0 0.8rem;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-full);

  &:hover {
    color: rgba(255, 255, 255, 1);
    background: var(--pallete-main-hover);
  }
}

input.Exchange-swap-input {
  padding: 0;
  max-width: 100%;
  font-size: var(--font-lg);
  line-height: 1.5;
  width: 100%;
  color: var(--text-color);
}

input.Exchange-swap-input.small {
  max-width: 100%;
}

.Exchange-swap-button-container {
  padding-top: 0.31rem;
}

.Exchange-swap-button {
  display: block;
  width: 100%;
}

table.Exchange-list-small {
  width: 100%;
  background: linear-gradient(45deg, rgba(11, 5, 55, 0.6) 0%, rgba(21, 3, 48, 0.6) 100%);
}

table.Exchange-list-small th,
table.Exchange-list-small td {
  font-weight: normal;
  text-align: left;
  padding: 1.05rem;
}

.Exchange-list-card-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 0.8rem;
}

.Exchange-list-title {
  width: 3.1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  white-space: nowrap;
  text-decoration: underline;
}

.Exchange-list-title p {
  margin: 0 !important;
}

.Exchange-list-info-label {
  margin-top: 0.31rem;
  color: #6c7284;
  white-space: nowrap;
}

.Exchange-list-muted {
  font-size: var(--font-base);
}

.Exchange-list-card .Exchange-list-side {
  text-align: right;
}

.Exchange-list-card .Exchange-list-size-info {
  margin-bottom: 0.8rem;
}

.Confirmation-box {
}

.Confirmation-box-main {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  font-size: var(--font-md);
  line-height: 1.5;
}
.Confirmation-box-main-icon {
  margin: 0.5rem 0;
}
.Confirmation-box-main-icon:after {
  content: "↓";
  line-height: 2rem;
}
.Confirmation-box-main-icon.dot:after {
  content: "•";
}

.Confirmation-box-row {
  margin-top: 1.5rem;
}

.Confirmation-box-button {
  margin-top: 1rem;
  width: 100%;
}

.Exchange-footer {
  text-align: center;
  padding-top: 3.1rem;
  height: 7.75rem;
}
.cancel-order-btn {
  margin-right: 1.5rem;
  min-width: 129px;
  font-size: var(--font-sm);
  height: 26px;
  background: var(--pallete-background-3);
  border: 1px solid var(--pallete-border);
  color: white;
  padding: 0;
  border-radius: 5px;
}
.cancel-order-btn:hover {
  opacity: 0.8;
}
.cancel-order-btn:disabled {
  opacity: 0.6;
}

.chart-positions:hover {
  opacity: 0.8;
}
.chart-positions.span:hover {
  opacity: 1;
}

.al-swap .Tooltip-popup {
  min-width: 25rem;
}

.position-list-collateral {
  display: flex;
  align-items: flex-end;
}

.position-list-collateral .edit-icon {
  cursor: pointer;
  margin-left: 0.5rem;
  display: inline-flex;
  align-items: center;
  opacity: 0.7;
}
.position-list-collateral .edit-icon:hover {
  opacity: 1;
}

.no-position-icon {
  width: auto;
  height: 50px;
}

.Useful-links-exchange {
  display: none;
}

.tv-chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.Position-leverage {
  margin-right: 0.25rem;
}

@media (max-width: 1500px) {
  .Exchange-swap-box {
    width: 35.75rem;
  }
}
::-webkit-scrollbar {
  display: none;
}

.ExchangeChart-bottom-mobile {
  position: absolute !important;
  bottom: 0rem;
  width: 100%;
  display: flex;
  overflow-x: scroll;
}

@media (max-width: 1100px) {
  .Exchange-swap-box {
    width: auto;
  }

  .Exchange-list-tab-container {
    border: none;
  }

  .ExchangeChart.tv .ExchangeChart-bottom-header {
    display: block;
  }

  .Exchange-content--layout-right {
    .Exchange-form-column {
      order: 2;
    }
  }

  .Exchange-content--layout-left {
    .Exchange-form-column {
      order: 1;
    }
  }

  .ExchangeChart.tv .ExchangeChart-bottom-stats {
    margin-left: 0;
  }

  .ExchangeChart.tv .ExchangeChart-bottom-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }

  .Exchange-list-tabs {
    margin-top: 0;
  }

  .Exchange-wallet-tokens {
    display: none;
  }

  .Exchange-swap-placeholder {
    display: none;
  }

  .ExchangeChart {
    height: 55rem;
  }

  .Exchange-content {
    grid-template-columns: 1fr;
  }

  .Exchange-lists.small {
    order: 3;
  }

  table.Exchange-lists.small {
    order: 3;
  }

  input.Exchange-swap-input {
    flex: 1 1 auto;
  }

  .ExchangeChart-bottom-controls .Tab .Tab-option {
    width: 3.875rem;
    padding: 0.465rem 0;
    font-size: var(--font-arbitrary-1-25rem);
  }
  .Useful-links-swapbox {
    display: none;
  }
  .Useful-links-exchange {
    display: block;
  }
}

@media (max-width: 450px) {
  .chart-positions {
    display: none;
  }
}
