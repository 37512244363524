.menu-item-header > p {
  color: var(--pallete-inactive);
  font-size: var(--font-base);
  margin: 0 !important;
  margin-left: 0.5em !important;
}

.menu-item-header {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-base);
  color: var(--pallete-inactive);
  font-size: var(--font-base);
  padding-bottom: 0.5em;
}
