.TokenSelector {
  display: flex;
  align-items: center;
}

.TokenSelector-token-name {
  margin-right: 1.5rem;
}

.TokenSelector.disabled {
  pointer-events: none;
}

.TokenSelector-box {
  display: flex;
  align-items: baseline;
  white-space: nowrap;
  font-size: var(--font-sm);
  line-height: 2.4rem;
}

.TokenSelector-box:not(.inactive) {
  cursor: pointer;
}

.TokenSelector-box:not(.inactive):hover {
  color: #7885ff;
}

.TokenSelector-shevron-wrap {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: var(--border-radius-full);
  border: none;
  background-color: var(--pallete-background-2);
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.Token-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.Token-info .Token-symbol {
  display: flex;
  flex-direction: column;
}
.Token-balance {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.TokenSelector-tokens {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
}

.TokenSelector-token-row {
  cursor: pointer;
  border: 1px solid var(--pallete-border);
  position: relative;
  background: transparent;
  border-radius: var(--border-radius-sm);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
}

.TokenSelector-tooltip.Tooltip {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.TokenSelector-tooltip-backing {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
}

.TokenSelector-token-row.disabled {
  cursor: not-allowed;
}

.TokenSelector-token-row.disabled .Token-info {
  opacity: 0.3;
}

.TokenSelector-token-row.disabled .Token-balance {
  opacity: 0.3;
}

.TokenSelector-token-row .Token-name {
  font-size: var(--font-base);
  line-height: 1.5;
  color: var(--text-color);
}

.TokenSelector-token-row:hover {
  background-color: var(--pallete-background-2);
}

.text-accent {
  color: var(--pallete-inactive);
  font-size: var(--font-base);
  line-height: 1.5;
}
