.Modal {
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;

  &.non-scrollable .Modal-content {
    overflow: visible;
  }

  &.placement-center {
    .Modal-title {
      width: max-content;
      text-align: left;
      font-size: var(--font-md);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: var(--font-lg);
      line-height: 1.5;
      font-weight: bold;
    }

    .Modal-title-bar {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
    }

    .Modal-content {
      border-radius: var(--border-radius-sm);
      padding: 2.5rem;
      overflow: overlay; /* Hide the scrollbar */
      border: 1px solid var(--pallete-border);

      &:not(.Modal-content--custom-width) {
        width: 38rem;
      }
    }

    .Modal-content::-webkit-scrollbar {
      width: 0; /* Hide the scrollbar in WebKit browsers */
    }

    .Modal-body {
      &.Modal-body--no-min-width {
        max-width: 100%;
      }

      position: relative;
    }
  }

  &.placement-right {
    align-items: flex-start;
    justify-content: flex-end;

    .Modal-title {
      font-size: var(--font-lg);
      line-height: 1.5;
      font-weight: bold;
      color: var(--text-color);
    }

    .Modal-title-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
    }

    .Modal-content {
      &:not(.Modal-content--custom-width) {
        width: 34.3rem;
      }
      position: absolute;
      max-height: unset;
      top: 0;
      bottom: 0;
      border: 1px solid var(--pallete-border);
      overflow: hidden;
      padding: 2.5rem 1.5rem 0 1.5rem;
    }

    .Modal-body {
      scroll-margin-right: 38.75rem;
      scrollbar-width: thin;
      overflow-y: auto;
      padding-bottom: calc(2.5rem + env(safe-area-inset-bottom));
    }

    .Modal-body::-webkit-scrollbar {
      display: none;
    }
  }
}

.Modal-back-button {
  position: absolute;
  left: 2rem;
  top: 0;
  width: 3rem;
  height: 3rem;
  align-items: center;
  justify-content: center;
  background: var(--pallete-background-2);
  border-radius: 50%;
  display: none;
}

.Modal-top-line {
  width: 8rem;
  height: 0.5rem;
  background-color: var(--pallete-border);
  border-radius: var(--border-radius-sm);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.8rem;
  display: none;
}

.Modal-scrollable .Modal-content .Modal-body {
  overscroll-behavior: smooth;
  max-height: calc(80vh - 5.5rem);
  overflow-y: auto;
  padding-right: 5px;
}

.Modal-title-wrapper {
  position: relative;
}

.Modal-backdrop {
  position: fixed;
  z-index: 10;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(9, 9, 12, 0.8);
}

.Modal-content {
  z-index: 20;
  position: relative;
  max-width: 100%;
  max-height: 90vh;
  overflow: auto;
  background: var(--pallete-background-1);
  border: 1px solid var(--pallete-border);
  display: flex;
  flex-direction: column;
}

.Modal-divider {
  border-top: 1px solid var(--pallete-border);
  margin: 2rem 0;
}

.Modal-body {
  flex: 1 1 0%;

  &:not(.Modal-body--no-top-margin) {
    margin-top: 2rem;
  }

  &::-webkit-scrollbar {
    width: 0.6rem;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--pallete-background-1);
    border-radius: var(--border-radius-full);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--pallete-inactive);
    border-radius: var(--border-radius-full);
  }
}

.Modal-close-button {
  text-align: right;
  width: 30px;
  height: 30px;
  background: var(--pallete-background-2);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Modal-close-icon {
  opacity: 1;
  cursor: pointer;
  text-align: right;
  display: inline-block;
}

.Modal-note {
  margin-bottom: 1.5rem;
  margin-top: 0.8rem;
}

@media (max-width: 1100px) {
  .Modal-top-line {
    display: block;
  }


  .Modal.placement-center .Modal-body {
    width: 100%;
    padding: 1.5rem;
  }

  .Modal.placement-right .Modal-close-button {
    display: flex;
  }

  .Modal-body {
    display: flex;
    flex-direction: column;
    margin-top: 0 !important;
    align-items: stretch;
    width: 100%;
  }

  .Modal.placement-center .Modal-title {
    display: block;
    position: static;
    transform: unset;
    color: green;
  }

  .Modal.placement-center .Modal-title-bar {
    justify-content: center;
  }

  .Modal-title-bar {
    justify-content: center;
    text-align: center;
    align-items: center;
    position: relative;
    margin-bottom: 2rem;
  }

  .Modal-title {
    font-size: var(--font-lg);
    font-weight: 700;
  }

  .Modal-sheet-content {
    height: 100%;

  }
  
  .react-modal-sheet-container {
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    border: 1px solid var(--pallete-border);
    border-top-left-radius: 4rem !important;
    border-top-right-radius: 4rem !important;
    font-size: var(--font-base);
   }

  .react-modal-sheet-content {
    width: 100%;
  }


  .react-modal-sheet-scroller {
    max-height: 100vh;
  }

  .react-modal-sheet-backdrop {
    background: rgba(9, 9, 12, 0.65);

  }
}
